import { useState } from 'react';
import { ReactComponent as Arrow } from 'Assets/icons/ArrowDown.svg';
import { ReactComponent as CheckMark } from 'Assets/icons/checkMark.svg';
import ContactsDetails, {
  addToOptionsEnum,
  whatDoYouWantEnum,
} from './ContactsDetails/ContactsDetails';
import { graphQlCall } from 'graphql/utils';
import { api, getToken } from 'utils/Utils';
import { PAGECRAFT_API_URL } from 'Constants';
import { SmartList, Tag } from '../Helper/types';
import clsx from 'clsx';
import queries from 'graphql/queries';
import Button from 'UILib/Button/Button';
import Loader from 'UILib/Loader/Loader';
import Popup from 'UILib/Popup/Popup';
import ContactsColumns from './ContactsColumns/ContactsColumns';
import ProgressBar from 'UILib/ProgressBar/ProgressBar';
import UploadCsv from './UploadCsv/UploadCsv';

import styles from './ImportContacts.module.scss';

interface IProps {
  onClose: () => void;
  open: boolean;
  tags: Tag[];
  smartLists: SmartList[];
  handleAddNewTag: (tagName: string) => Promise<Tag>;
  fetchUserContacts: () => void;
}

export interface TableRows {
  csvColumn: string;
  contactField: string;
}

const steps = [
  { key: 1, name: 'Upload' },
  { key: 2, name: 'Columns' },
  { key: 3, name: 'Details' },
];

const supportedContactFields = [
  'Full Name',
  'First Name',
  'Last Name',
  'Email',
  'Phone',
  'Country',
  'Address',
  'Tags',
];

type ContactData = string[];
type ContactsArray = ContactData[];
type ContactObject = { [key: string]: string };

function createMergedObject(data: ContactsArray): ContactObject {
  const keys = data[0];
  const finalObject: ContactObject = {};

  keys.forEach((key, index) => {
    for (let i = 1; i < data.length; i++) {
      const value = data[i][index];
      if (value) {
        finalObject[key] = value;
        break;
      }
    }

    if (!finalObject[key]) {
      finalObject[key] = '';
    }
  });

  return finalObject;
}

const ImportContacts = ({
  onClose,
  open,
  tags,
  smartLists,
  handleAddNewTag,
  fetchUserContacts,
}: IProps) => {
  const [step, setStep] = useState<number>(1);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadEta, setUploadEta] = useState(0);
  const [tableRows, setTableRows] = useState<TableRows[]>([]);
  const [previewData, setPreviewData] = useState({});
  const [addContactToOption, setAddContactToOption] = useState<string>(
    addToOptionsEnum.ALL
  );
  const [whatYouWantOption, setWhatYouWantOption] = useState<string>(
    whatDoYouWantEnum.ADD_NEW_AND_UPDATE
  );
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [newSmartListName, setNewSmartListName] = useState<string>('');
  const [smartListId, setSmartListId] = useState<string>('');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  function normalizeFieldName(str: string) {
    return (
      str
        .toLowerCase()
        // remove punctuation and other symbols but allow spaces
        .replace(/[^\w\s]/g, '')
        .trim()
    );
  }

  //Super simple algo that will allow us to detect matching fields.
  function getSimilarityScore(a: string, b: string) {
    // Simple approach:
    // - Split into words
    // - Count how many words or partial matches we get.

    const wordsA = a.split(/\s+/);
    const wordsB = b.split(/\s+/);

    let score = 0;
    for (const wA of wordsA) {
      for (const wB of wordsB) {
        // If either word contains the other, increment score.
        if (wA.includes(wB) || wB.includes(wA)) {
          score += Math.min(wA.length, wB.length);
        }
      }
    }
    return score;
  }

  /**
   * Attempt to find the best matching supported field for a given input field.
   * Returns the best match if above a certain threshold, otherwise null.
   */
  function findBestMatchField(inputField: string) {
    const normalizedInput = normalizeFieldName(inputField);

    let bestMatch: string | null = null;
    let highestScore = 0;

    for (const supportedField of supportedContactFields) {
      const normalizedSupported = normalizeFieldName(supportedField);
      const score = getSimilarityScore(normalizedInput, normalizedSupported);

      if (score > highestScore) {
        highestScore = score;
        bestMatch = supportedField;
      }
    }

    const THRESHOLD = 2;
    return highestScore >= THRESHOLD ? bestMatch : 'Do Not Import';
  }
  const handleNext = async () => {
    try {
      if (step === 1) {
        if (file) {
          setIsLoading(true);
          const request = new FormData();
          request.append('file', file);

          const data = await api(
            `${PAGECRAFT_API_URL}/contacts/preview-upload`,
            'POST',
            request
          );

          const rows: any = [];
          for (const item of data.contacts[0]) {
            let field = findBestMatchField(item);
            //check if field is already match and if so then skip it and do not import.
            const index = rows.findIndex(
              (row: any) => row.contactField === field
            );
            if (index >= 0) {
              field = 'Do Not Import';
            }

            //check if separated parts of name is already marked for import and if so then skip it.
            if (field === 'Full Name') {
              const index = rows.findIndex((row: any) => {
                return (
                  row.contactField === 'Last Name' ||
                  row.contactField === 'First Name'
                );
              });
              if (index >= 0) {
                field = 'Do Not Import';
              }
            }

            rows.push({
              csvColumn: item,
              contactField: field,
            });
          }

          setTableRows(rows);
          setPreviewData(createMergedObject(data.contacts));

          setStep(step + 1);
        }
      } else {
        setStep(step + 1);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeContactsData = (
    index: number,
    key: string,
    value: string | boolean
  ) => {
    setTableRows((prevRows) =>
      prevRows.map((row, idx) =>
        idx === index ? { ...row, [key]: value } : row
      )
    );
  };

  const addNewTag = async (tagName: string) => {
    const response = await handleAddNewTag(tagName);

    setSelectedTags([...selectedTags, response]);
  };

  const handleAddTag = (tag: Tag) => {
    setSelectedTags((prevTags) => {
      const tagExists = prevTags.some(
        (existingTag) => existingTag._id === tag._id
      );

      return tagExists ? prevTags : [...prevTags, tag];
    });
  };

  const handleRemoveTag = (tagId: string) => {
    setSelectedTags((prevTags) => prevTags.filter((tag) => tag._id !== tagId));
  };

  const handleAddContacts = async () => {
    try {
      setErrors({});
      setIsLoading(true);
      let smartList = '';
      if (addContactToOption === addToOptionsEnum.NEW_SMART_LIST) {
        if (!newSmartListName) {
          return setErrors({
            smartListName: 'Smart List name is required',
          });
        }

        if (!selectedTags.length) {
          return setErrors({
            tags: 'Tags are required',
          });
        }

        const newSmartList = await graphQlCall({
          queryTemplateObject: queries.CREATE_SMART_LIST,
          values: {
            name: newSmartListName,
            includeAll: selectedTags.map((tag) => tag._id),
          },
          headerType: 'USER-AUTH',
        });

        smartList = newSmartList._id;
      } else if (addContactToOption === addToOptionsEnum.EXISTING_SMART_LIST) {
        if (!smartListId) {
          return setErrors({
            smartList: 'Smart List is required',
          });
        }
        smartList = smartListId;
      }

      if (file) {
        const request = new FormData();
        request.append('file', file);
        request.append('tags', JSON.stringify(selectedTags));
        request.append('smartListId', smartList);
        request.append('smartListType', addContactToOption);
        request.append('contactsColumns', JSON.stringify(tableRows));
        request.append(
          'isUpdate',
          (
            whatYouWantOption === whatDoYouWantEnum.ADD_NEW_AND_UPDATE
          ).toString()
        );

        const response = await fetch(`${PAGECRAFT_API_URL}/contacts/upload`, {
          method: 'POST',
          body: request,
          headers: {
            Authorization: getToken(),
          },
        });

        if (response.body) {
          const reader = response.body.getReader();
          const decoder = new TextDecoder('utf-8');

          setStep(4);

          while (true) {
            // Read the next chunk
            const { done, value } = await reader.read();

            if (done) {
              break;
            }

            // Decode the chunk into a string
            const chunk = decoder.decode(value, { stream: true });
            const lines = chunk.split('\n');
            if (lines.length > 0) {
              const line = lines[lines.length - 2];
              const values = line.split('|');
              if (values.length === 2) {
                setUploadProgress(parseInt(values[0]));
                setUploadEta(parseInt(values[1]));
              }
            }
          }
        }

        fetchUserContacts();
      }
      handleCloseModal();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    onClose();
    setStep(1);
    setTableRows([]);
    setPreviewData({});
    setAddContactToOption(addToOptionsEnum.ALL);
    setWhatYouWantOption(whatDoYouWantEnum.ADD_NEW_AND_UPDATE);
    setSelectedTags([]);
    setNewSmartListName('');
    setSmartListId('');
    setFile(undefined);
  };

  //convert seconds in to human readble minutes and seconds format
  const convertEtaToHuman = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    if (minutes > 0) {
      return `${minutes}m ${remainingSeconds}s`;
    } else {
      return `${remainingSeconds}s`;
    }
  };

  if (!open) return null;

  return (
    <Popup onClose={handleCloseModal} wrapperClassName={styles.popup}>
      <div className={styles.container}>
        <div className={styles.title}>Import Contacts</div>
        <div className={styles.subtitle}>Complete three quick steps</div>
        <div className={styles.steps}>
          {steps.map((item) => (
            <div className={styles.stepItem} key={item.key}>
              <div
                className={clsx(styles.stepNumber, {
                  [styles.passedStep]: step > item.key,
                })}
              >
                {step > item.key ? <CheckMark /> : <span>{item.key}</span>}
              </div>
              <div className={styles.stepName}>{item.name}</div>
              {item.key !== 3 && <Arrow className={styles.arrow} />}
            </div>
          ))}
        </div>
        {step !== 2 && <div className={styles.divider} />}
        {step === 1 && <UploadCsv file={file} setFile={setFile} />}
        {step === 2 && (
          <ContactsColumns
            data={tableRows}
            previewData={previewData}
            onChange={handleChangeContactsData}
          />
        )}
        {step === 3 && (
          <ContactsDetails
            contactOption={addContactToOption}
            onChangeContactOption={setAddContactToOption}
            whatYouWantOption={whatYouWantOption}
            onChangeWantOption={setWhatYouWantOption}
            tags={tags}
            smartLists={smartLists}
            selectedTags={selectedTags}
            handleAddTag={handleAddTag}
            handleRemoveTag={handleRemoveTag}
            onChangeSmartListName={setNewSmartListName}
            smartListName={newSmartListName}
            setSmartListId={setSmartListId}
            smartListId={smartListId}
            errors={errors}
            handleAddNewTag={addNewTag}
          />
        )}
        {step === 4 && (
          <div className={styles.progressBarContainer}>
            <div className={styles.bar}>
              <ProgressBar progress={uploadProgress} />
              <div className={styles.label}>
                Processing file. Almost there! About{' '}
                {convertEtaToHuman(uploadEta)} left
              </div>
            </div>
          </div>
        )}
        {step !== 2 && <div className={styles.divider} />}
        <div className={styles.buttonsContainer}>
          {step > 1 && (
            <Button
              appearance="stroke"
              onClick={() => setStep(step - 1)}
              disabled={isLoading}
            >
              Previous
            </Button>
          )}
          {step < 3 ? (
            <Button
              appearance="solid"
              onClick={handleNext}
              disabled={isLoading}
            >
              {isLoading ? <Loader color="#ffffff" size={16} /> : 'Next'}
            </Button>
          ) : (
            <Button onClick={handleAddContacts} disabled={isLoading}>
              {isLoading ? (
                <Loader color="#ffffff" size={16} />
              ) : (
                'Add Contacts'
              )}
            </Button>
          )}
        </div>
      </div>
    </Popup>
  );
};

export default ImportContacts;
