import { connect } from 'react-redux';
import { Descendant } from 'slate';
import { RootState } from 'store/rootReducer';
import {
  updateBookleTemplateBlocks,
  updateBookleTemplateTextEditor,
} from 'store/books/booksActions';
import { IBookleTemplateEditor } from 'store/books/booksReducer';
import { FontFamilies } from 'Components/FontSelector/FontFamilies';
import { BookleTemplateBlock } from 'types';
import { findItemById } from '../utils';
import TextEditor from 'Editors/TextEditor/TextEditor';
import FontManager from 'CoverEditor/FontManager';

import styles from './ButtonBlock.module.scss';

const __tmp__initialValue: Descendant[] = [
  {
    type: 'paragraph',
    children: [{ text: 'Learn More', color: '#ffffff', align: 'center' }],
  },
];

interface IProps {
  item: BookleTemplateBlock;
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
  templateBlocks: BookleTemplateBlock[];
  updateTextEditor: (payload: IBookleTemplateEditor) => void;
}

const ButtonBlock = ({
  item,
  updateBlocks,
  templateBlocks,
  updateTextEditor,
}: IProps) => {
  const onChange = (i: Descendant[]) => {
    i.map((e: any) => {
      e.children.map((item: any) => {
        if (item.font) {
          FontManager.getInstance().loadFont(
            FontFamilies.find((e) => e.value === item.font && !e.webSafe)
              ?.label || '',
            item.weight
          );
        }
      });
    });
    const newArray = [...templateBlocks];
    const textItem: BookleTemplateBlock = findItemById(
      newArray,
      item.id as string
    );
    textItem.text = i;
    updateBlocks(newArray);
  };

  return (
    <div
      className={styles.container}
      style={{
        ...(item.hasColor ? { backgroundColor: item.color } : {}),
        justifyContent: item.alignment as any,
        ...(item.hasSpacing
          ? {
              paddingBottom: item.styles?.blockSpacing?.paddingBottom,
              paddingTop: item.styles?.blockSpacing?.paddingTop,
              paddingLeft: item.styles?.blockSpacing?.paddingLeft,
              paddingRight: item.styles?.blockSpacing?.paddingRight,
            }
          : {}),
      }}
    >
      <div
        className={styles.button}
        style={{
          ...item.styles,
        }}
      >
        <div className={styles.textEditor} id={`text_editor_${item.id}`}>
          <TextEditor
            onChange={onChange}
            setEditor={(editor, selection) =>
              updateTextEditor({ editor, selection })
            }
            canEdit={!item.readOnly}
            initialValue={item.text || __tmp__initialValue}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateBlocks: state.books.bookleTemplateBlocks,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
  updateTextEditor: (payload: IBookleTemplateEditor) =>
    updateBookleTemplateTextEditor(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonBlock);
