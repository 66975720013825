import { useEffect, useRef, useState } from 'react';
import styles from './TemplateView.module.scss';
import { ReactComponent as CopyIcon } from 'Assets/icons/Copy.svg';
import { ReactComponent as ListBullets } from 'Assets/icons/ListBullets.svg';
import { ReactComponent as HeadlineIcon } from 'Assets/icons/Headline.svg';
import { ReactComponent as ImageIcon } from 'Assets/icons/rounded-image.svg';
import { ReactComponent as AudioIcon } from 'Assets/icons/podcast.svg';

import TemplateNode, { INode, INodeEvent, INodeEvents } from '../Nodes/Node';

import TemplateEditorCanvas from '../TemplateCanvas/TemplateCanvas';
import { Dropdown, Menu } from 'antd';
import type { MenuProps } from 'antd';
import UserInput, { INodeUserInput, ITemplateForm } from '../Nodes/UserInput';
import GenerateList, { INodeGenerateList } from '../Nodes/GenerateList';
import GenerateText, { INodeGenerateText } from '../Nodes/GenerateText';
import GenerateImage, { INodeGenerateImage } from '../Nodes/GenerateImage';
import {
  GenerateListDefaultData,
  GenerateTextDefaultData,
  UserInputDefaultData,
  GenerateImageDefaultData,
} from '../constants';

const _extraViewPaddingY = 100;
const _viewHeightIncreaseIncrements = 500;

export interface INodeAdder {
  sourceNode: INode;
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
  targetNode?: INode;
}

interface IProps {
  templateActions: any;
  onVariableChange: (node: INode, oldVariable: string | null, newVariable: string | null) => void;
  onChange: (nodes: INode[]) => void;
  onExpose: (node: INode) => void;
}

const TemplateView = (props: IProps) => {
  const [nodes, setNodes] = useState<any>({});
  const [nodeAdder, setNodeAdder] = useState<INodeAdder | null>(null);
  const [createMenuOpen, setCreateMenuOpen] = useState(false);
  const createMenuRef = useRef<Menu>(null);
  const viewContainerRef = useRef<HTMLDivElement>(null);
  const [viewHeight, setViewHeight] = useState(1000);

  const [nodeEvent, setNodeEvent] = useState<INodeEvent | null>();

  const createNode = (type: string) => {
    let node: INode | null = null;
    switch (type) {
      case 'UserInput':
        node = { ...UserInputDefaultData } as INodeUserInput;
        break;

      case 'GenerateList':
        node = { ...GenerateListDefaultData } as INodeGenerateList;
        break;

      case 'GenerateText':
        node = { ...GenerateTextDefaultData } as INodeGenerateText;
        break;

      case 'GenerateImage':
        node = { ...GenerateImageDefaultData } as INodeGenerateImage;
        break;
    }
    if (node) {
      node.connections = [];
      node.id = generateRandomId();
    }
    return node;
  };

  const getNodeMaxY = () => {
    let maxY = 0;
    for (const key in nodes) {
      const node = nodes[key];
      if (node.y + node.height > maxY) {
        maxY = node.y + node.height;
      }
    }

    return maxY;
  };

  const updateViewSize = () => {
    const maxY = getNodeMaxY();
    if (maxY + _extraViewPaddingY > viewHeight) {
      setViewHeight(maxY + _extraViewPaddingY + _viewHeightIncreaseIncrements);
    }
  };

  //TODO: sync both CLient and Server Structure to minimize data conversion hassle
  const serializeUserInput = (node: INodeUserInput, data: any) => {
    node.forms = [];
    for (const formData of data.forms) {
      const form: ITemplateForm = {
        name: formData.name,
        fields: [],
      };

      for (const variables of formData.variables) {
        form.fields.push({
          id: variables.id,
          type: variables.type,
          label: variables.label,
          options: variables.options,
        });
      }

      node.forms.push(form);
    }
  };

  const serializeGenerateText = (node: INodeGenerateText, data: any) => {
    node.variable = data.id;
    //TODO: need to align variable names here
    // node.id = data.id;
    // node.variable = data.variable;
    node.prompt = data.prompt;
  };

  const serializeGenerateList = (node: INodeGenerateList, data: any) => {
    node.variable = data.id;
    //TODO: need to align variable names here
    // node.id = data.id;
    // node.variable = data.variable;
    node.prompt = data.prompt;
  };

  const serializeGenerateImage = (node: INodeGenerateList, data: any) => {
    node.variable = data.id;
    //TODO: need to align variable names here
    // node.id = data.id;
    // node.variable = data.variable;
    node.prompt = data.prompt;
  };

  const sortNodes = (nodesToSort: any) => {
    for (const key in nodesToSort) {
      const node: INode = nodesToSort[key];
      if (node && node.type === 'UserInput') {
        let height = node.height;
        //TODO: need to add ability to sort nodes that is not connected to UserInput directly.
        for (const connection of node.connections) {
          nodesToSort[connection].x = node.x + 60;
          nodesToSort[connection].y = height + 110;
          height += nodesToSort[connection].height + 90;
        }
      }
    }
    return nodesToSort;
  };

  const generateNodesBasedOnTemplate = (data: any, nodes: INode[]) => {
    if (data.type) {
      const node = createNode(data.type);
      if (node) {
        if (node.type === 'UserInput') {
          serializeUserInput(node as INodeUserInput, data);
        } else if (node.type === 'GenerateText') {
          serializeGenerateText(node as INodeGenerateText, data);
        } else if (node.type === 'GenerateList') {
          serializeGenerateList(node as INodeGenerateList, data);
        } else if (node.type === 'GenerateImage') {
          serializeGenerateImage(node as INodeGenerateImage, data);
        }

        if (data.metadata) {
          node.x = data.metadata['x'];
          node.y = data.metadata['y'];
          node.width = data.metadata['width'];
          node.height = data.metadata['height'];
        }

        if (data.postFunctions && data.postFunctions.length > 0) {
          for (const postFunction of data.postFunctions) {
            const keys = Object.keys(postFunction);
            for (const key of keys) {
              const childNode = generateNodesBasedOnTemplate(
                postFunction[key],
                nodes
              );
              if (childNode) {
                childNode.operation = key;
                connectNodes(node, childNode);
              }
            }
          }
        }

        nodes.push(node);
        return node;
      }
    }
    return null;
  };

  useEffect(() => {
    if (props.templateActions) {
      let sortingNeeds = false;

      if (!props.templateActions.metadata) {
        sortingNeeds = true;
      }

      const nodesRef: INode[] = [];
      generateNodesBasedOnTemplate(props.templateActions, nodesRef);

      //convert from Array to Dictionary
      let nodesOut: any = {};
      for (const node of nodesRef) {
        nodesOut[node.id] = node;
      }

      if (sortingNeeds) {
        sortNodes(nodesOut);
      }

      setNodes(nodesOut);
    }
  }, [props.templateActions]);

  useEffect(() => {
    props.onChange(nodes);

    updateViewSize();
  }, [nodes]);

  const generateRandomId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  const handleNodeCreation = (info: { key: string }) => {
    setCreateMenuOpen(false);
    setNodeAdder(null);

    const node = createNode(info.key);
    if (node && nodeAdder) {
      node.x = nodeAdder.targetX;
      node.y = nodeAdder.targetY;
      connectNodes(nodeAdder.sourceNode, node);

      nodes[node.id] = node;
      setNodes({ ...nodes });
    }
  };

  const connectNodes = (source: INode, target: INode) => {
    source.connections.push(target.id);
    if (source.type === 'GenerateList') {
      if (target.operation === null) {
        target.operation = 'each';
      }
    } else {
      target.operation = null;
    }
  };

  const disconnectNode = (target: INode) => {
    for (const id in nodes) {
      const node = nodes[id];
      for (let i = 0; i < node.connections.length; i++) {
        const connection = node.connections[i];
        if (connection === target.id) {
          node.connections.splice(i, 1);
        }
      }
    }

    setNodes({ ...nodes });
  };

  const handleContextMenuClick = (info: { key: string }) => {
    if (info.key === 'SortNodes') {
      const sortedNodes = sortNodes(nodes);
      setNodes({ ...sortedNodes });
    }
  };

  const contextMenuItems = {
    onClick: handleContextMenuClick,
    items: [
      {
        label: 'Sort nodes',
        key: 'SortNodes',
      },
    ],
  };

  const createMenuItems = {
    ref: createMenuRef,
    onClick: handleNodeCreation,
    items: [
      // {
      //   key: 'UserInput',
      //   icon: <HeadlineIcon fill="#000000" />,
      //   label: <div>User Input</div>,
      // },
      // {
      //   key: 'GenerateList',
      //   icon: <ListBullets fill="#000000" />,
      //   label: <div>Generate List</div>,
      // },
      {
        key: 'GenerateText',
        icon: <CopyIcon fill="#000000" />,
        label: <div>Generate Text</div>,
      },
      {
        key: 'GenerateImage',
        icon: <ImageIcon fill="#000000" />,
        label: <div>Generate Image</div>,
      },
      // {
      //   key: 'GenerateAudio',
      //   icon: <AudioIcon fill="#000000" />,
      //   label: <div>Generate Audio</div>,
      // },
    ],
  };

  const getNodeByInputSlotCoordinates = (clientX: number, clientY: number) => {
    const rect = viewContainerRef.current?.getBoundingClientRect();
    if (rect) {
      const x = clientX - rect.left;
      const y = clientY - rect.top;
      for (const key in nodes) {
        const node = nodes[key];
        const slotHitCircleSize = 10;
        const slotYOffset = +30;
        if (
          x < node.x + slotHitCircleSize &&
          x > node.x - slotHitCircleSize &&
          y < node.y + slotHitCircleSize + slotYOffset &&
          y > node.y - slotHitCircleSize + slotYOffset
        ) {
          return node;
        }
      }
      return null;
    }
  };

  const handleMouseUp = (e: MouseEvent) => {
    setNodeEvent(null);

    if (nodeAdder) {
      if (nodeAdder.targetNode) {
        nodeAdder.sourceNode.connections.push(nodeAdder.targetNode.id);
        setNodeAdder(null);
      } else {
        setCreateMenuOpen(true);
      }
    }
  };

  const handleMouseDown = (e: MouseEvent) => {
    if (createMenuOpen) {
      if (createMenuRef.current) {
        const rect = createMenuRef.current.menu?.list.getBoundingClientRect();
        if (rect) {
          if (
            e.clientX < rect.left ||
            e.clientX > rect.right ||
            e.clientY < rect.top ||
            e.clientY > rect.bottom
          ) {
            setCreateMenuOpen(false);
            setNodeAdder(null);
          }
        }
      }
    }
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (nodeEvent && nodes) {
      const node = nodes[nodeEvent.sender.id];
      if (node) {
        if (nodeEvent.type === INodeEvents.Create) {
          if (nodeAdder) {
            nodeAdder.targetX += event.movementX;
            nodeAdder.targetY += event.movementY;
            nodeAdder.targetNode = getNodeByInputSlotCoordinates(
              event.clientX,
              event.clientY
            );
            setNodeAdder(nodeAdder);
          }
        }

        if (nodeEvent.type === INodeEvents.Resize) {
          node.width += event.movementX;
          node.height += event.movementY;
        } else if (nodeEvent.type === INodeEvents.Move) {
          node.x += event.movementX;
          node.y += event.movementY;
        }
        setNodes({ ...nodes });
      }
    }
  };

  const handleNodeChange = (node: INode) => {
    if (nodes[node.id].variable !== node.variable) {
      props.onVariableChange(node, nodes[node.id].variable, node.variable);
    }

    nodes[node.id] = node;

    setNodes({ ...nodes });
  };

  const handleNodeEvent = (event: INodeEvent) => {
    setNodeEvent(event);

    if (event.type === INodeEvents.Create) {
      const node = nodes[event.sender.id];
      if (node) {
        const offset = { x: 25, y: node.height + 50 };
        setNodeAdder({
          sourceNode: node,
          sourceX: node.x + offset.x,
          sourceY: node.y + offset.y,
          targetX: node.x + offset.x,
          targetY: node.y + offset.y,
        });
      }
    }
    if (event.type === INodeEvents.Expose) {
      props.onExpose(event.sender);
      setNodeEvent(null);
    }
    if (event.type === INodeEvents.Delete) {
      disconnectNode(event.sender);
      delete nodes[event.sender.id];
      setNodes({ ...nodes });
      setNodeEvent(null);
    }
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousedown', handleMouseDown);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousedown', handleMouseDown);
    };
  }, [nodeEvent, nodes, nodeAdder, createMenuOpen]);

  const getAllParentNodes = (node: INode, nodes: { [key: string]: INode }) => {
    const parentNodes: INode[] = [];
    const visited = new Set<string>();

    const traverseParents = (currentNode: INode) => {
      if (visited.has(currentNode.id)) return;
      visited.add(currentNode.id);

      for (const key in nodes) {
        const parentNode = nodes[key];
        if (parentNode.connections.includes(currentNode.id)) {
          parentNodes.push(parentNode);
          traverseParents(parentNode);
        }
      }
    };

    traverseParents(node);
    return parentNodes;
  };

  const nodeFactory = (node: INode) => {
    const parentNodes = getAllParentNodes(node, nodes);
    const variables = parentNodes.flatMap((node) => {
      if (node.type === 'UserInput') {
        return (node as INodeUserInput).forms.flatMap((form) =>
          form.fields.map((field) => field.id)
        );
      }
      return node.variable || '';
    });

    switch (node.type) {
      case 'UserInput':
        return (
          <UserInput
            key={node.id}
            node={node as INodeUserInput}
            onChange={handleNodeChange}
            onEvent={handleNodeEvent}
          />
        );
      case 'GenerateList':
        return (
          <GenerateList
            key={node.id}
            variables={variables}
            node={node as INodeGenerateList}
            onChange={handleNodeChange}
            onEvent={handleNodeEvent}
          />
        );
      case 'GenerateText':
        return (
          <GenerateText
            key={node.id}
            variables={variables}
            node={node as INodeGenerateText}
            onChange={handleNodeChange}
            onEvent={handleNodeEvent}
          />
        );
      case 'GenerateImage':
        return (
          <GenerateImage
            key={node.id}
            variables={variables}
            node={node as INodeGenerateImage}
            onChange={handleNodeChange}
            onEvent={handleNodeEvent}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.rootContainer}>
      <Dropdown menu={contextMenuItems} trigger={['contextMenu']}>
        <div style={{ height: `${viewHeight}px` }} ref={viewContainerRef}>
          <TemplateEditorCanvas nodes={nodes} nodeAdder={nodeAdder} />
          {Object.keys(nodes).map((id) => {
            return nodeFactory(nodes[id]);
          })}

          {nodeAdder && (
            <Dropdown menu={createMenuItems} open={createMenuOpen}>
              <div
                className={styles.nodeAdderMarker}
                style={{
                  transform: nodeAdder.targetNode ? 'scale(2)' : 'scale(1)',
                  left: nodeAdder.targetX - 6,
                  top: nodeAdder.targetY - 6,
                }}
              />
            </Dropdown>
          )}
        </div>
      </Dropdown>
    </div>
  );
};

export default TemplateView;
