import { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Socket } from 'socket.io-client';
import { createSocket, getToken } from 'utils/Utils';
import { IAutoApp } from 'store/autosaas/autosaasReducer';
import { RootState } from 'store/rootReducer';
import { AutoSassIconsOptions } from 'Components/SideMenu/constants';
import { IGenerationTask } from 'types';
import { graphQlCall } from 'graphql/utils';
import {
  GenerationItemCardState,
  GenerationItemCardType,
} from 'Components/GenerationTemplate/GenerationTemplateCard/GenerationTemplateCard';
import clsx from 'clsx';
import queries from 'graphql/queries';
import SideMenu from 'Components/SideMenu/SideMenu';
import GenerationTemplateCard from 'Components/GenerationTemplate/GenerationTemplateCard/GenerationTemplateCard';
import DashboardHeader from 'Components/DashboardHeader/DashboardHeader';
import burgerSvg from 'Assets/icons/burger.svg';
import Pagination from 'UILib/Pagination/Pagination';

import styles from './AutoApp.module.scss';

const sortOptions = [
  {
    label: 'Last Update',
    value: 'updatedAt',
    sortAsc: false,
  },
  {
    label: 'File Name',
    value: 'name',
    sortAsc: true,
  },
];

const LIMIT = 20;

interface IProps {
  autoApps?: IAutoApp[];
}

interface IParams {
  appId: string;
}

const AutoApp = ({ autoApps }: IProps) => {
  const socket = useRef<Socket | null>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const [sortBy, setSortBy] = useState<string>('updatedAt');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [appData, setAppData] = useState<IAutoApp>();
  const [generationTasks, setGenerationTasks] = useState<IGenerationTask[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const { appId } = useParams<IParams>();

  useEffect(() => {
    if (appData?._id) {
      if (searchTerm === undefined) setLoading(true);
      graphQlCall({
        queryTemplateObject: queries.GET_GENERATION_TASKS_QUERY_WITH_PAGINATION,
        headerType: 'USER-AUTH',
        values: {
          type: appData?._id,
          skip: (currentPage - 1) * LIMIT,
          limit: LIMIT,
          search: searchTerm,
          sortBy,
          sortAsc: sortOptions.find((item) => item.value === sortBy)?.sortAsc,
        },
      })
        .then((item) => {
          setGenerationTasks(item.tasks || []);
          setTotalCount(item.total);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [searchTerm, sortBy, currentPage, appData]);

  useEffect(() => {
    if (appId && !!autoApps?.length) {
      setAppData(autoApps.find((item) => item._id === appId));
    }
  }, [appId, autoApps]);

  useEffect(() => {
    const taskIdsToWatch: string[] = [];
    for (const task of generationTasks) {
      if (task.status === 'GENERATING') {
        taskIdsToWatch.push(task._id);
      }
    }
    if (taskIdsToWatch.length > 0) {
      if (!socket.current) {
        socket.current = createSocket();
        if (socket.current) {
          socket.current.on(
            'generating-task-info-response',
            handleGenerationTaskUpdates
          );
          socket.current.on('connect', () => {
            console.log('connected to server');

            for (const taskId of taskIdsToWatch) {
              subscribeOnGenerationTaskUpdates(taskId);
            }
          });
          return () => {
            if (socket.current) {
              socket.current.off(
                'generating-task-info-response',
                handleGenerationTaskUpdates
              );
            }
          };
        }
      }
    }
  }, [generationTasks]);

  const subscribeOnGenerationTaskUpdates = (taskId: string) => {
    console.log('subscribeOnGenerationTaskUpdates:', taskId);
    if (socket.current) {
      socket.current.emit('generating-task-info', {
        taskId,
        token: getToken(),
      });
    }
  };

  const handleGenerationTaskUpdates = (payload: any) => {
    if (payload.action === 'task is complete') {
      const taskIndex = generationTasks.findIndex(
        (item) => item._id === payload.task._id
      );
      generationTasks[taskIndex].status = 'COMPLETE';
      setGenerationTasks([...generationTasks]);
    }
  };

  const handleDelete = (id: string) => {
    setGenerationTasks(generationTasks.filter((item) => item._id !== id));
    setTotalCount(totalCount - 1);
  };

  const handleTaskClick = (senderId: String) => {
    history.push(
      `/console/generationTask/${senderId}/edit?app=${appData?._id}`
    );
  };

  const handleCreateNewFile = () => {
    history.push(
      `/console/file/${appData?._id}/${appData?.template?._id}/create`
    );
  };

  return (
    <div className={styles.container}>
      <div
        className={clsx(styles.pageNavigationWrapper, {
          [styles.moveLeft]: !isSidebarOpen,
        })}
      >
        <SideMenu />
        <div
          className={styles.sidebarToggleBtnBlock}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          <img src={burgerSvg} alt="burger svg" />
        </div>
      </div>
      {loading ? (
        <main className={styles.loadingCards}>
          <div className={styles.cardsTitleLoading} />
          <div className={styles.cardsContainerLoading}>
            {Array.from({ length: 20 }, (_, index) => (
              <div key={index} className={styles.cardLoading} />
            ))}
          </div>
        </main>
      ) : (
        <main
          className={clsx(
            styles.containerContent,
            isSidebarOpen ? styles.contentRight : styles.content
          )}
        >
          <div className={styles.overflowContainer}>
            <DashboardHeader
              handleAddNewItem={handleCreateNewFile}
              buttonText="New Project"
              sortOptions={sortOptions}
              sortBy={sortBy}
              handleSort={(value: string) => setSortBy(value)}
              handleSearch={setSearchTerm}
              title="Your Projects"
              total={`${totalCount} Projects`}
            />
            <div className={styles.generationTemplateElements}>
              {generationTasks.map((item, index) => (
                <GenerationTemplateCard
                  key={item._id}
                  item={item}
                  state={
                    item.status === 'GENERATING'
                      ? GenerationItemCardState.Generating
                      : undefined
                  }
                  type={GenerationItemCardType.Task}
                  onClick={handleTaskClick}
                  onDelete={() => handleDelete(item._id)}
                  icon={
                    AutoSassIconsOptions.find(
                      (icon) =>
                        icon.path ===
                        (appData?.iconUrl || 'icons/appIcons/templates.svg')
                    )?.icon
                  }
                />
              ))}
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(totalCount / LIMIT)}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </main>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  autoApps: state.autosaas.autoApps,
});

export default connect(mapStateToProps)(AutoApp);
