import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import Link from 'UILib/Link/Link';
import Input from 'UILib/Input/Input';
import Button from 'UILib/Button/Button';
import Dropdown from 'UILib/Dropdown/Dropdown';
import TimeInput from 'UILib/TimeInput/TimeInput';
import RadioButton from 'UILib/RadioButton/RadioButton';
import Calendar from 'Components/Common/DatePicker/Calendar/Calendar';
import EmailTestModal from 'Components/EmailTestModal/EmailTestModal';
import CustomDropdown from 'Components/CustomDropdown/CustomDropdown';
import queries from 'graphql/queries';
import Loader from 'UILib/Loader/Loader';
import { timezones } from 'utils/constants';
import { graphQlCall } from 'graphql/utils';
import { IBroadcast, ISender, ISmartList } from 'types';
import { ReactComponent as Back } from 'Assets/arrow-left-back.svg';
import { ReactComponent as MailIcon } from 'Assets/icons/mail.svg';
import { ReactComponent as EditIcon } from 'Assets/icons/edit2.svg';
import { ReactComponent as ClockIcon } from 'Assets/icons/clockNotFill.svg';
import { ReactComponent as CalendarIcon } from 'Assets/icons/calendar.svg';
import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';

import styles from './CompleteBroadcastCampaign.module.scss';

interface IProps {
  onGoBack: () => void;
  onEmailSubjectChanged: (subject: string) => void;
  subject: string;
  maildraftId: string;
  htmlContent: string;
  broadcastId: string;
  broadcastData?: IBroadcast;
}

const CompleteBroadcastCampaign = ({
  onGoBack,
  onEmailSubjectChanged,
  subject,
  maildraftId,
  htmlContent,
  broadcastId,
  broadcastData,
}: IProps) => {
  const [senders, setSenders] = useState<ISender[]>([]);
  const [domains, setDomains] = useState<ISender[]>([]);
  const [smartLists, setSmartLists] = useState<ISmartList[]>([]);
  const [emailSubject, setEmailSubject] = useState<string>(subject);
  const [selectedOption, setSelectedOption] = useState<'send-now' | 'send-on'>(
    'send-now'
  );
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [selectedTimezone, setSelectedTimezone] = useState<string>(
    localStorage.getItem('preferredTimezone') ?? ''
  );
  const [selectedSmartList, setSelectedSmartList] = useState<string>('');
  const [selectedSender, setSelectedSender] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showTestEmailModal, setShowTestEmailModal] = useState<boolean>(false);
  const [errors, setErrors] = useState<{
    sender: boolean;
    smartList: boolean;
    date: boolean;
    time: boolean;
  }>({ sender: false, smartList: false, date: false, time: false });

  const verifiedSenders = useMemo(() => {
    return senders
      .filter((sender) =>
        domains.some(
          (domain) =>
            domain._id === sender.mailDomain?._id &&
            domain.status === 'Verified'
        )
      )
      .map((sender) => ({
        label: sender.email,
        value: sender._id,
      }));
  }, [senders, domains]);

  useEffect(() => {
    if (broadcastData) {
      setSelectedSender(broadcastData?.sender?._id || '');
      setSelectedSmartList(broadcastData?.smartList?._id || '');
    }
  }, [broadcastData]);

  const history = useHistory();

  useEffect(() => {
    graphQlCall({
      queryTemplateObject: queries.GET_MAIL_SENDERS_QUERY,
      headerType: 'USER-AUTH',
    }).then(setSenders);
    graphQlCall({
      queryTemplateObject: queries.GET_MAIL_DOMAINS_QUERY,
      headerType: 'USER-AUTH',
    }).then(setDomains);
    graphQlCall({
      queryTemplateObject: queries.GET_USER_SMART_LISTS,
      headerType: 'USER-AUTH',
    }).then(setSmartLists);
  }, []);

  const checkValidation = () => {
    let errors = {
      sender: !selectedSender,
      smartList: !selectedSmartList,
      date: false,
      time: false,
    };
    const isSendingInfoValid = !!selectedSender && !!selectedSmartList;

    if (selectedOption === 'send-on') {
      errors = {
        ...errors,
        date: !selectedDate,
        time: !selectedTime,
      };

      setErrors(errors);
      return isSendingInfoValid && !!selectedDate && !!selectedTime;
    }

    setErrors(errors);
    return isSendingInfoValid;
  };

  const updateBroadcast = async () => {
    try {
      const isValid = checkValidation();

      if (!isValid) return;

      let sendAt = Date.now();
      if (selectedOption === 'send-on') {
        const timezoneOffsetMatch = selectedTimezone.match(
          /([+-]\d{2}):(\d{2})/
        );
        const timezoneOffsetHours = parseFloat(timezoneOffsetMatch?.[1] || '0');
        const timezoneOffsetMinutes = parseFloat(
          timezoneOffsetMatch?.[2] || '0'
        );

        const formattedDate = selectedDate?.toISOString().split('T')[0];

        const sendAtDateString = `${formattedDate}T${selectedTime}:00.000Z`;

        const sendAtDate = new Date(sendAtDateString);

        sendAtDate.setUTCHours(
          sendAtDate.getUTCHours() + timezoneOffsetHours,
          sendAtDate.getUTCMinutes() + timezoneOffsetMinutes
        );

        sendAtDate.setHours(sendAtDate.getHours() + timezoneOffsetHours);
        sendAtDate.setMinutes(sendAtDate.getMinutes() + timezoneOffsetMinutes);
        sendAt = sendAtDate.getTime();
      }

      const broadcast = await graphQlCall({
        queryTemplateObject: queries.UPDATE_BROADCAST_MUTATION,
        values: {
          id: broadcastId,
          senderId: selectedSender,
          smartListId: selectedSmartList,
          mailSubject: emailSubject,
          mailDraftId: maildraftId,
          sendAt: sendAt / 1000, //NOTE: we need to send EPOCH in seconds
        },
        headerType: 'USER-AUTH',
      });
      return broadcast?._id;
    } catch (error) {
      console.error(error);
    }
  };

  const handleEmailSubjectChange = async (value: string) => {
    setEmailSubject(value);
    onEmailSubjectChanged(value);
  };

  const handleBroadcastLaunch = async () => {
    try {
      setLoading(true);

      const result = await updateBroadcast();
      if (!result) return;

      localStorage.setItem('preferredTimezone', selectedTimezone);
      const response = await graphQlCall({
        queryTemplateObject: queries.START_BROADCAST_MUTATION,
        values: {
          id: result,
        },
        headerType: 'USER-AUTH',
      });
      if (response.status === 'ACTIVE' || response.status === 'WAITING') {
        return history.push('/console/outreach/broadcasts');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <EmailTestModal
        htmlScript={htmlContent}
        emailSubject={emailSubject}
        open={showTestEmailModal}
        onClose={() => setShowTestEmailModal(false)}
      />
      <div className={styles.container}>
        <Link to="" className={styles.backLink}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onGoBack();
            }}
            className={styles.backButton}
          >
            <Back />
            Back
          </div>
        </Link>
        <div className={styles.formWrapper}>
          <div className={clsx(styles.section, styles.firstSection)}>
            <MailIcon className={styles.icon} width={32} height={24} />
            <div className={styles.title}>Complete Campaign</div>
            <div className={styles.subtitle}>
              Check everything and set final settings
            </div>
            <div className={styles.formInput}>
              Sender:
              <CustomDropdown
                label="Select Sender"
                value={selectedSender}
                className={styles.dropdown}
                options={[
                  // {
                  //   value: 'AutoFunnel',
                  //   label: (
                  //     <div className={styles.autoFunnelSender}>
                  //       <span>Autofunnel</span>
                  //       <span className={styles.description}>
                  //         The email will be sent on behalf of Autofunnel
                  //       </span>
                  //     </div>
                  //   ),
                  // },
                  ...verifiedSenders,
                ]}
                onChange={(item) => {
                  setErrors({ ...errors, sender: false });
                  setSelectedSender(item);
                }}
                footer={
                  <div
                    className={styles.addSender}
                    onClick={() =>
                      history.push('/console/settings/domains-senders')
                    }
                  >
                    <PlusIcon className={styles.plusIcon} />
                    Add sender
                  </div>
                }
                hasErrors={errors.sender}
              />
              {errors.sender && (
                <div className={styles.error}>Sender is required</div>
              )}
            </div>
            <div className={styles.formInput}>
              Smart List:
              <Dropdown
                type="stroke"
                label="Select List"
                value={selectedSmartList}
                className={styles.dropdown}
                options={smartLists.map((list) => ({
                  label: list.name,
                  value: list._id,
                }))}
                onChange={(item) => {
                  setErrors({ ...errors, smartList: false });
                  setSelectedSmartList(item);
                }}
                hasErrors={errors.smartList}
              />
              {errors.smartList && (
                <div className={styles.error}>Smart List is required</div>
              )}
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.secondSectionWrapper}>
              <div className={clsx(styles.formInput, styles.noMargin)}>
                Email Subject
                <Input
                  border="stroke"
                  className={styles.input}
                  value={emailSubject}
                  onChange={(e) => handleEmailSubjectChange(e.target.value)}
                />
              </div>
              <div className={styles.radioButtonGroup}>
                <RadioButton
                  name="Send Now"
                  value="send-now"
                  label="Send Now"
                  checked={selectedOption === 'send-now'}
                  onChange={(value) =>
                    setSelectedOption(value as 'send-now' | 'send-on')
                  }
                  className={styles.radioButton}
                />
                <RadioButton
                  name="Send On"
                  value="send-on"
                  label="Send On"
                  checked={selectedOption === 'send-on'}
                  onChange={(value) =>
                    setSelectedOption(value as 'send-now' | 'send-on')
                  }
                  className={styles.radioButton}
                />
              </div>
              {selectedOption === 'send-on' && (
                <div className={styles.dateContainer}>
                  <Input
                    readOnly
                    border="stroke"
                    placeholder="DD:MM:YYYY"
                    className={styles.dateInput}
                    onClick={() => setOpenCalendar(true)}
                    prefixIcon={<CalendarIcon width={16} height={16} />}
                    value={
                      selectedDate
                        ? selectedDate
                            .toLocaleDateString('en-GB')
                            .split('/')
                            .join(':')
                        : ''
                    }
                    error={errors.date ? 'Date is required' : ''}
                  />
                  <TimeInput
                    value={selectedTime}
                    className={styles.timeInput}
                    onTimeChange={(item) => {
                      setErrors({ ...errors, time: false });
                      setSelectedTime(item);
                    }}
                    prefixIcon={<ClockIcon width={16} height={16} />}
                    error={errors.time ? 'Time is required' : ''}
                  />
                  <Dropdown
                    type="stroke"
                    size="medium"
                    label={timezones[7]}
                    value={selectedTimezone}
                    className={styles.timezoneDropdown}
                    options={timezones.map((tz) => ({ label: tz, value: tz }))}
                    onChange={(timezone) => setSelectedTimezone(timezone)}
                  />
                  <Calendar
                    selectionType="single"
                    startDate={selectedDate}
                    setDate={(newDate: Date) => {
                      setErrors({ ...errors, date: false });
                      setSelectedDate(newDate);
                    }}
                    isOpen={openCalendar}
                    onClose={() => setOpenCalendar(false)}
                  />
                </div>
              )}
            </div>
            <Button
              width={160}
              height={50}
              appearance="highlighted"
              postfixIcon={!loading && <MailIcon />}
              className={styles.launchButton}
              onClick={handleBroadcastLaunch}
              disabled={loading}
            >
              {loading ? <Loader size={20} color="#ffffff" /> : 'Launch'}
            </Button>
          </div>
        </div>
        <div className={styles.previewWrapper}>
          <div className={styles.title}>Email Preview</div>
          <div className={styles.buttonsWrapper}>
            <Button
              width={180}
              height={40}
              appearance="solid"
              prefixIcon={<MailIcon />}
              onClick={() => setShowTestEmailModal(true)}
            >
              Send Test Email
            </Button>
            <Button
              width={40}
              height={40}
              appearance="stroke"
              prefixIcon={<EditIcon />}
              onClick={onGoBack}
            />
          </div>
          <div className={styles.previewContainer} id="templatePreview">
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CompleteBroadcastCampaign;
