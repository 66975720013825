import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store/rootReducer';
import { IAppsNames } from 'store/autosaas/autosaasReducer';
import { APPS } from 'Components/AutoSaas/constants';
import { ITopic, ITopicValues } from 'types';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';
import Loader from 'UILib/Loader/Loader';
import Pagination from 'UILib/Pagination/Pagination';
import DashboardHeader from 'Components/DashboardHeader/DashboardHeader';
import ConfirmArchivationPopup from 'Components/Domains/ConfirmArchivationPopup';
import CopyCraftEmptyPage from '../CopyCraftEmptyPage';
import EditTopicModal from '../EditTopicModal';
import CopyCraftItem from '../CopyCraftItem/CopyCraftItem';
import AddTopicModal from '../AddTopicModal';

import styles from './CopyCraftDashboard.module.scss';

interface IProps {
  autoSassAppsNames?: IAppsNames[];
}

const LIMIT = 20;

const CopyCraftDashboard = ({ autoSassAppsNames }: IProps) => {
  const history = useHistory();
  const [topics, setTopics] = useState<ITopic[]>([]);
  const [openAddTopicModal, setOpenAddTopicModal] = useState(false);
  const [openEditTopicModal, setOpenEditTopicModal] = useState(true);
  const [deleteTopicId, setDeleteTopicId] = useState<string>();
  const [editingTopic, setEditingTopic] = useState<ITopic | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchedText, setSearchedText] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('updatedAt');
  const [pageName, setPageName] = useState<string>('Project');

  const sortOptions = [
    {
      label: 'Last Update',
      value: 'updatedAt',
    },
    {
      label: `Project Name`,
      value: 'name',
    },
  ];

  useEffect(() => {
    if (!!autoSassAppsNames?.length) {
      setPageName(
        autoSassAppsNames.find((e) => e.key === APPS.COPYCRAFT)?.name ||
          'Project'
      );
    }
  }, [autoSassAppsNames]);

  useEffect(() => {
    fetchTopics();
  }, [currentPage, searchedText, sortBy]);

  const fetchTopics = async (): Promise<void> => {
    try {
      if (!topics?.length && !searchedText) {
        setLoading(true);
      }
      const newTopics = await graphQlCall({
        queryTemplateObject: QUERIES.GET_USER_TOPICS_WITH_PAGINATION,
        headerType: 'USER-AUTH',
        values: {
          limit: LIMIT,
          skip: (currentPage - 1) * LIMIT,
          search: searchedText,
          sortBy,
          sortAsc: true,
        },
      });
      setTopics(newTopics.topics);
      setTotalCount(newTopics.total);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddTopic = async (name: string): Promise<void> => {
    setOpenAddTopicModal(false);
    const newTopic = await graphQlCall({
      queryTemplateObject: QUERIES.CREATE_AI_TEXT_TOPIC_MUTATION,
      values: {
        name,
      },
      headerType: 'USER-AUTH',
    });
    const newTopics = [...topics];
    newTopics.push(newTopic);
    setTopics(newTopics);
    history.push(`/console/copy-craft/${newTopic._id}`);
  };

  const handleEditTopic = async (values: ITopicValues): Promise<void> => {
    try {
      await graphQlCall({
        queryTemplateObject: QUERIES.UPDATE_AI_TEXT_TOPIC_MUTATION,
        values: {
          id: editingTopic?._id,
          ...values,
        },
        headerType: 'USER-AUTH',
      });

      setOpenEditTopicModal(false);
      const newTopics = topics.map((topic) => {
        if (editingTopic?._id === topic._id) {
          topic = { ...topic, ...values };
        }
        return topic;
      });
      setTopics(newTopics);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteTopic = async (): Promise<void> => {
    try {
      if (!deleteTopicId) return;

      await graphQlCall({
        queryTemplateObject: QUERIES.DELETE_AI_TEXT_TOPIC_MUTATION,
        values: {
          id: deleteTopicId,
        },
        headerType: 'USER-AUTH',
      });
      const newTopics = topics.filter((topic) => topic._id !== deleteTopicId);
      setTopics(newTopics);

      setDeleteTopicId(undefined);
    } catch (error) {
      console.error(error);
    }
  };

  const onEdit = (item: ITopic) => {
    setEditingTopic(item);
    setOpenEditTopicModal(true);
  };

  const handleSearch = (text: string) => {
    setCurrentPage(1);
    setSearchedText(text);
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loader}>
          <Loader />
        </div>
      ) : topics?.length || searchedText ? (
        <div className={styles.contentContainer}>
          <DashboardHeader
            handleSort={(value: string) => setSortBy(value)}
            sortOptions={sortOptions}
            title={`Your Projects`}
            buttonText={`New Project`}
            total={`${totalCount} Projects`}
            handleAddNewItem={() => setOpenAddTopicModal(true)}
            handleSearch={handleSearch}
            sortBy={sortBy}
          />
          {topics.map((item: ITopic) => (
            <CopyCraftItem
              item={item}
              key={item._id}
              onDelete={setDeleteTopicId}
              onEdit={onEdit}
            />
          ))}
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(totalCount / LIMIT)}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      ) : (
        <CopyCraftEmptyPage
          onAddClicked={() => setOpenAddTopicModal(true)}
          pageName={pageName}
        />
      )}

      <AddTopicModal
        open={openAddTopicModal}
        onClose={() => setOpenAddTopicModal(false)}
        addTopic={handleAddTopic}
      />
      {editingTopic && (
        <EditTopicModal
          open={openEditTopicModal}
          onClose={() => setOpenEditTopicModal(false)}
          onEditTopic={handleEditTopic}
          values={{ name: editingTopic.name }}
        />
      )}
      <ConfirmArchivationPopup
        onClose={() => setDeleteTopicId(undefined)}
        onDonePressed={handleDeleteTopic}
        open={!!deleteTopicId}
        description="Are you sure you want to delete your generated copy? It will not be available to you and all data will be lost."
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  autoSassAppsNames: state.autosaas.appsNames,
});

export default connect(mapStateToProps)(CopyCraftDashboard);
