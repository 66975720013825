import React, { useState, useEffect } from 'react';
import s from './BillingSettings.module.scss';
import PrimaryButton from '../Common/PrimaryButton/PrimaryButton';
import InputV1 from '../Common/Inputs/InputV1/InputV1';
import {
  fetchUserDetails,
  updateUserBilling,
} from '../../store/user/userActions';
import { RootState } from '../../store/rootStore';
import stripeLogo from '../../Assets/icons/stripeLogo.svg';
import { connect } from 'react-redux';
import { IUserDetails } from 'types';
import Spinner from 'Components/Common/Spinner/Spinner';
import StripeIntegrationSuccesfullPopup from 'Components/Domains/StripeIntegrationSuccesfullPopup';

interface IProps {
  userDetails: IUserDetails;
  isLoading: boolean;
  isStripeIntegrationSuccesfull: true | null | false;
  fetchUserDetails: () => void;
  updateUserBilling: (stripePublicKey: string, stripeSecretKey: string) => void;
}

const BillingSettings = (props: IProps) => {
  const [stripePublicKey, setStripePublicKey] = useState('');
  const [stripeSecretKey, setStripeSecretKey] = useState('');
  const [
    stripeIntegrationSuccesfullPopupOpen,
    setStripeIntegrationSuccesfullPopupOpen,
  ] = useState(false);
  const [
    isStripeIntegrationSuccesfull,
    setIsStripeIntegrationSuccesfull,
  ] = useState<boolean>(false);
  const [publicKeyError, setPublicKeyError] = useState(false);
  const [secretKeyError, setSecretKeyError] = useState(false);

  useEffect(() => {
    props.fetchUserDetails();
  }, []);

  useEffect(() => {
    console.log(props.isStripeIntegrationSuccesfull);

    if (props.isStripeIntegrationSuccesfull) {
      setStripeIntegrationSuccesfullPopupOpen(true);
      setIsStripeIntegrationSuccesfull(true);
    }
    if (props.isStripeIntegrationSuccesfull === false) {
      setStripeIntegrationSuccesfullPopupOpen(true);
      setIsStripeIntegrationSuccesfull(false);
    }
  }, [props.isStripeIntegrationSuccesfull]);

  useEffect(() => {
    setStripePublicKey(props.userDetails.stripePublicKey);
    setStripeSecretKey(props.userDetails.stripeSecretKey);
  }, [props.userDetails]);

  const handleSave = () => {
    let hasValidated = true;
    setPublicKeyError(false);
    setSecretKeyError(false);
    const cleanedPublicKey = stripePublicKey.trim();
    setStripePublicKey(cleanedPublicKey);
    const cleanedSecretKey = stripeSecretKey.trim();
    setStripeSecretKey(cleanedSecretKey);
    const regPublik = /^pk_\w{4}_[a-zA-Z0-9]{1,150}$/;
    const regSecret = /^sk_\w{4}_[a-zA-Z0-9]{1,150}$/;
    if (!regPublik.test(cleanedPublicKey)) {
      setPublicKeyError(true);
      hasValidated = false;
    }
    if (!regSecret.test(cleanedSecretKey)) {
      setSecretKeyError(true);
      hasValidated = false;
    }
    if (!hasValidated) {
      return;
    }
    props.updateUserBilling(cleanedPublicKey, cleanedSecretKey);
  };

  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <h2 className={s.contentHeader}>My Billing Details</h2>
        <div className={s.stripeLogoSvgBlock}>
          <img src={stripeLogo} alt="stripe logo" />
        </div>
        <div className={s.stripeFormText}>Stripe Integration</div>
        <div className={s.input}>
          <InputV1
            width={330}
            placeholder={'Stripe Publishable Key'}
            value={stripePublicKey}
            setState={setStripePublicKey}
            type={'text'}
          />
          {publicKeyError && <span>validation error</span>}
        </div>
        <div className={s.input}>
          <InputV1
            width={330}
            placeholder={'Secret Key'}
            value={stripeSecretKey}
            setState={setStripeSecretKey}
            type={'text'}
          />
          {secretKeyError && <span>validation error</span>}
        </div>
        <div className={s.stripeNote}>
          <p>Need help finding an API key from Stripe? See our</p>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://help.autofunnel.ai/support/solutions/articles/202000060195-integrating-stripe"
          >
            Integrating Stripe
          </a>
          article.
        </div>
        <div className={s.buttonBlock}>
          {props.isLoading ? (
            <Spinner size={50} />
          ) : (
            <PrimaryButton
              width={158}
              onClick={() => handleSave()}
              isBlackBtn={true}
              borderRadius={50}
            >
              Save
            </PrimaryButton>
          )}
        </div>
        <StripeIntegrationSuccesfullPopup
          isOpen={stripeIntegrationSuccesfullPopupOpen}
          onClose={() => setStripeIntegrationSuccesfullPopupOpen(false)}
          isStripeIntegrationSuccesfull={isStripeIntegrationSuccesfull}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  userDetails: state.user.details,
  isLoading: state.user.isLoading,
  isStripeIntegrationSuccesfull: state.user.isStripeIntegrationSuccesfull,
});

const mapDispatchToProps = {
  fetchUserDetails: () => fetchUserDetails(),
  updateUserBilling: (stripePublicKey: string, stripeSecretKey: string) =>
    updateUserBilling(stripePublicKey, stripeSecretKey),
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingSettings);
